import * as React from "react";
import termsPdf from '../pdfs/Brico_Cookie Policy v1.0.pdf'
import Seo from "../components/seo";
const CookiePolicy = () => {
  return (
    <>
      <Seo title="Cookies policy" description="Cookies policy for Evistamp" />
      <div>
        <iframe className="w-full h-screen" src={termsPdf} />
      </div>
    </>
  );
};
export default CookiePolicy;
